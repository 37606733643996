import { Controller } from "@hotwired/stimulus";

export default class DatepickerController extends Controller {
  static values = {
    onChangeDate: String
  };

  connect() {
    const self = this.element;
    const format = self.dataset.format || 'D, dd M yyyy'
    const autoclose = self.dataset.autoclose || true
    const container = self.dataset.container;
    const onChangeDateCallback = window[this.onChangeDateValue];
    const startDate = self.dataset.startDate || null;
    const endDate = self.dataset.endDate || null;

    const datepicker = $(self).datepicker({
      format: format,
      autoclose: autoclose,
      container: container,
      startDate: startDate,
      endDate: endDate
    });

    if (onChangeDateCallback) datepicker.on('changeDate', onChangeDateCallback);

    datepicker.on('changeDate', (event) => {
      const e = new CustomEvent('change', {
        detail: { selectedDate: event.date },
        bubbles: true,
        cancelable: true,
        target: this.element
      });

      this.element.dispatchEvent(e);
    })
  }
}
