import React, { useState } from 'react';
import Form from "src/components/MultiMessages/Form";

const MembersMultiMessageForm = () => {
  const [show, setShow] = useState(true);

  return (
    <>
      {show && <Form show={show} setShow={setShow} conversationPurpose={'online_memberships'}/>}
    </>
  )
}

export default MembersMultiMessageForm;