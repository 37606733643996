import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import NeoModal from "src/NeoModal";

export default class extends Controller {
  static targets = ['imagePreview'];

  addImage(e) {
    e.preventDefault();

    const button = e.target;
    const { url } = button.dataset;

    axios.get(url).then((response) => {
      const modal = NeoModal.add(response.data, {});

      modal.addEventListener('neo_modal:success', () => {
        $(modal).modal('hide');
        button.innerHTML = 'View image';
      });
    });
  }

  removeImage(e) {
    e.preventDefault();

    const target = e.target;
    const form = target.closest('form');
    const { url, id } = target.dataset;

    axios.delete(url).then((response) => {
      const parser = new DOMParser();
      const parsedHTML = parser.parseFromString(response.data, 'text/html');
      const parsedForm = parsedHTML.querySelector('form');
      const addImageButton = document.querySelector(`#view_image_${id}`);

      if (addImageButton) addImageButton.innerHTML = 'Add image';

      form.innerHTML = parsedForm.innerHTML;
    });
  }

  previewImage(e) {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const imagePreview = this.imagePreviewTarget;
        imagePreview.src = event.target.result;
        imagePreview.classList.remove('hide');
      };

      reader.readAsDataURL(file);
    }
  }
}
