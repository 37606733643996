import { Controller } from "@hotwired/stimulus";
import anx from "src/nextgen/Payments/anx";
import axios from "axios";

class PaymentController extends Controller {
  static targets = ['submit', 'paymentForm', 'paymentElement', 'spinner', 'errorElement', 'parentModal'];

  connect() {
    this.parentModal = document.querySelector(`#${this.paymentFormTarget.dataset.parentModal}`);
    this.connection = anx();

    let options = {
      mode: this.paymentFormTarget.dataset.mode,
      currency: 'gbp',
      setupFutureUsage: this.paymentFormTarget.dataset.setupFutureUsage,
      loader: 'always',
      allowedCardBrands: ['visa', 'mastercard']
    }

    if (this.paymentFormTarget.dataset.captureMethod) {
      options['captureMethod'] = this.paymentFormTarget.dataset.captureMethod;
    }

    if (this.paymentFormTarget.dataset.amount) {
      options['amount'] = Number(this.paymentFormTarget.dataset.amount);
    }

    let stripe = App.stripe;

    if (this.paymentFormTarget.dataset.stripeAccount) {
      stripe = Stripe(App.stripe._apiKey, 
        { stripeAccount: this.paymentFormTarget.dataset.stripeAccount, betas: ["blocked_card_brands_beta_2"]});
    }

    const elements = stripe.elements(options);

    const paymentElement = elements.create('payment', { terms: { card: 'never' } });

    this.parentModal.addEventListener('neo_modal:submitting_form', async(event) => {
      const { error } = await elements.submit();

      if (error) {
        // TODO: handle??
      } else {
        this.submitHandler(stripe,
                           elements, 
                           event.detail.submitFunction, 
                           event.detail.errorFunction,
                           event.detail.successFunction);
      }
      
    });

    if (this.paymentFormTarget.dataset.offline == 'true') {
      this.spinnerTarget.style.display = '';
      document.body.dataset.paymentProcessing = 'true';

      setTimeout(() => this.getClientSecret().then(obj => {
        this.subscribe(obj.id)
        this.processOffline(obj);
      }), 200);
    } else {
      paymentElement.mount('#payment-element');
    }
  }

  getClientSecret() {
    return axios
      .post(this.paymentFormTarget.dataset.clientSecretUrl, {}, { headers: { "X-Requested-With": "XMLHttpRequest" } })
      .then(response => {

        if (response.data.reload) {
          window.location.reload();
        } else {
          return response.data.modification
        }
      })
  }

  processOffline(modification) {
    const { id, orderId } = modification;

    axios
      .post(`/orders/${orderId}/checkout/payment/confirm_offline?modification_id=${id}`)
      .then(() => window.location = this.paymentFormTarget.dataset.returnUrl)
      .catch(() => Helper.flash_message('Something went wrong'));
  }

  stripeAction() {
    if (this.paymentFormTarget.dataset.mode == 'payment') {
      return 'confirmPayment';
    } else if (this.paymentFormTarget.dataset.mode == 'setup') {
      return 'confirmSetup';
    }
  }

  async submitHandler(stripe, elements, submitFunction, errorFunction, successFunction) {
    submitFunction()
      .then(async (response) => {
        this.subscribe(response.data.modification.id, successFunction);
        Cookies.set('show_new_order_id', response.data.orderId);

        const clientSecret = response.data.modification.clientSecret;
        const stripeResponse = await stripe[this.stripeAction()]({
          elements,
          clientSecret,
          confirmParams: { return_url: this.paymentFormTarget.dataset.returnUrl },
          redirect: 'if_required'
        });

        if (stripeResponse.error) {
          this.errorElementTarget.innerText = stripeResponse.error.message;
          errorFunction({ error: stripeResponse.error.message });
        }
      })
      .catch((error) => { errorFunction(error) });
  }

  subscribe(modificationId, successCallback) {
    return this.connection.subscribe(modificationId, (data) => {
      switch (data.status) {
        case 'succeeded':
          this.handleSuccess(data, successCallback);
          break;
        case 'failed':
          this.handleFailed(data);
          break;
      }
    })
  }

  handleSuccess(data, successCallback) {
    if (data.message) {
      Helper.flash_message('success', data.message);
    }

    this.parentModal.dispatchEvent(new CustomEvent('neo_modal:payment_success', { detail: { data: data }}));

    successCallback();
  }

  handleFailed(data) {
    if (data.message) {
      Helper.flash_message('error', data.message)
    }
  }
}

export default PaymentController;
