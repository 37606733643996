import React from "react";
import { Controller } from "@hotwired/stimulus";
import ReactDOM from "react-dom";
import Form from "src/components/Messenger/MembersMultiMessageForm";

export default class MembersMultiMessageButtonController extends Controller {
  connect() {
    this.element.addEventListener('click', (e) => {
      const mountPoint = document.createElement('div');

      this.element.parentNode.replaceChild(mountPoint, this.element.nextSibling);

      ReactDOM.render(<Form />, mountPoint);
    })
  }
}
