import { Controller } from "@hotwired/stimulus"
import axios from "axios";

export default class extends Controller {
  static targets = ['messagesLink'];

  connect() {
    const el = this.element;
    const conversationPurpose = el.dataset.conversationPurpose;

    axios
      .get('/messenger/unread', { params: { conversation_purpose: conversationPurpose } })
      .then((rensponse) => {
        this.messagesLinkTargets.map(el => {
          el.insertAdjacentHTML('beforeend', rensponse.data);
        })
      }).catch(() => {});
  }
}

